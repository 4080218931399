html{
  overflow-y: scroll;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container
{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.product
{
  width: 240px;
  margin: 10px;
}

.productListingImage
{
  width: 240px;
  height: 240px;
}

.productThumbnails
{
  display: flex;
  width: 240px;
  justify-content: center;
}

.productThumbnail
{
  float: left;
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.productName
{
  font-weight: bold;
  width: 170px;
  text-align: left;
  font-size: 0.8em;
  float: left;
}

.productDetails
{
  width: 240px;
}

.productPrice
{
  width: 70px;
  font-size: 1.1em;
  float: left;
}

.productDetailsImages
{
  width: 500px;
}

.productTitle
{
  padding-bottom: 20px;
}

.textLeft
{
  text-align: left;
}

.textCenter
{
  text-align: center;
}

.productTabs h1{
  text-align: center;
  font-size: 1.7em;
}

.productTabs h2{
  text-align: center;
  font-size: 1.7em;
}

#description-text
{
  text-align: left
}

.marginTop70
{
  margin-top: 70px;
}

.marginTop150
{
  margin-top: 150px;
}

.marginTop15
{
  margin-top: 15px;
}

.marginTop25
{
  margin-top: 25px;
}

.marginLeft
{
  margin-left: 10px;
}

.marginLeft15
{
  margin-left: 15px;
}

.marginLeft25
{
  margin-left: 25px;
}

.smallLogo
{
  height: 25px;
}

.MuiFormLabel-root.Mui-focused
{
  color: #00acc1 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline
{
  border-color: #00acc1 !important;
}

.clearfix
{
  clear: both;
}

.fa-facebook
{
    margin-right: 5px;
}

.messageContainer
{
  float: left;
  width: 100%;
  text-align: left;
}

.errorMessage
{
  color: red;
}

.successMessage
{
  color: green;
}